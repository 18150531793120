.navigation-root {
  float: right;
}

.mainMenuContainer {
  width: auto;
  display: table;
  margin: 16px auto auto;
  padding: 16px;
  border: 1px solid rgba(224, 227, 225, 1);
  background: white;
  border-radius: 4px;
  position: relative;
}

.navigation-root, .mainMenuContainer {
  .link {
    display: inline-block;
    padding: 4px;
    text-decoration: none;
    cursor: pointer;
    font-size: 1em;
  }

  .link,
  .link:active,
  .link:visited {
    color: rgb(62, 66, 62);
    font-weight: 400;
  }

  .link:hover {
    color: rgb(163, 25, 26);
    background: rgba(163, 25, 26, 0.05);
    border-radius: 3px;
  }

  .highlight {
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.05);
    color: rgb(163, 25, 26);

  }

  .highlight:hover {
    background: rgba(163, 25, 26, 0.15);
  }

  .spacer {
    color: rgb(55, 55, 55);
  }

  .bigLink {
    padding: 8px;
    font-size: 1.125em; /* ~18px */
    margin-left: 4px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 170px;
    box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.2);
    z-index: 101;
  }

  .dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 1em;
  }

  .dropdownContent a:hover {
    background: rgba(163, 25, 26, 0.05);
  }

  .dropdown:hover .dropdownContent {
    display: block;
  }

  .centerH {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .center {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .menuHeader {
    text-align: center;
    width: 135px;
    height: 50px;
    font-size: 20px;
    background: rgb(0, 115, 139);
    color: white;
    border-radius: 4px;
    position: relative;
    font-weight: 300;
    margin-bottom: 8px;
  }

  .menuContainer {
    float: left;
    margin: 8px;
  }

  .menuItemIcon {
    display: inline-block;
    width: 24px;
  }

  .menuItemContainer {
    float: left;
    display: flex;
    justify-content: normal;
    flex-direction: column;
    width: 100%;
  }

  .menuItem {
    position: relative;
    height: 80px;
    margin-bottom: 8px;
  }

  .menuItemLink {
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
}

