
@import '../../components/variables.css';

.changeLog h2 {
  color: #425aff;
}

.changeLog h2, h3, h4, h5, h6, ul {
  margin: 8px 0px;
}

.changeLog {
  font-weight: 400;
  margin-bottom: 64px;
}
