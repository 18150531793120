
.home-root {
  padding-left: 20px;
  padding-right: 20px;

  .container {
    margin: 0 auto;
    padding: 0 0 50px;
    max-width: var(--max-content-width);
  }

  .newsItem {
    margin: 0 0 2rem;
  }

  .newsTitle {
    font-size: 1.5rem;
  }

  .mainMenuHeader {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    font-weight: 400;
    color: #616161;
    border: 1px solid rgba(224, 227, 225, 1);
    padding: 2px 64px 3px;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #FAFAFA;
    font-size: 14px;
  }

}
