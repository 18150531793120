.footer-root {
  background: #919191;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;

  .container {
    margin: 0 auto;
    padding: 8px;
    text-align: center;
  }

  .text {
    color: rgba(255, 255, 255, 0.5);
  }

  .spacer {
    color: rgba(255, 255, 255, 0.3);
  }

  .text,
  .link {
    padding: 2px 5px;
    font-size: 1em;
  }

  .link,
  .link:active,
  .link:visited {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
  }

  .link:hover {
    color: rgba(255, 255, 255, 1);
  }
}

