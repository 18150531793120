.printarea {
  display: none;
}

@media print {
  body * {
    visibility: hidden;
  }

  .printarea, .printarea * {
    visibility: visible;

  }

  .printarea {
    display: unset;
    position: absolute;
    top: -128px;
    left: 0;
    margin: 0;
    padding: 0;
  }

  .configInfosViewHolder {
    padding: 0 !important;
  }

  .hide {
    display: none !important;
  }

  a[href]:after {
    content: none !important;
  }

  @page {
    size: auto;   /* auto is the initial value */
    margin: 0cm 0cm 1cm 0cm;
  }

  body {
    margin-top: 2.5cm;
    margin-bottom: 2.5cm;
  }
}
