
:root {
  /*
   * Typography
   * ======================================================================== */

  --font-family-base: 'Roboto', 'HelveticaNeue-Light', sans-serif;

  /*
   * Layout
   * ======================================================================== */

  --max-content-width: calc(100% - 64px);

  /*
   * Spaces
   * ======================================================================== */

  --space-half: 2px;
  --space-1: calc(var(--space-half) * 2);
  --space-2: calc(var(--space-1) * 2);
  --space-4: calc(var(--space-1) * 4);
  --space-6: calc(var(--space-1) * 6);
  --space-8: calc(var(--space-1) * 8);
  --space-12: calc(var(--space-1) * 12);
  --space-16: calc(var(--space-1) * 16);

  /*
   * Font Size
   * ======================================================================== */

  --font-size-smaller: 8pt;
  --font-size-small: 10pt;
  --font-size-mid: 12pt;
  --font-size-large: 16pt;
  --font-size-larger: 20pt;
  --font-size-largest: 24pt;

  /*
   * Media queries breakpoints
   * ======================================================================== */

  --screen-xs-min: 480px; /* Extra small screen / phone */
  --screen-sm-min: 768px; /* Small screen / tablet */
  --screen-md-min: 992px; /* Medium screen / desktop */
  --screen-lg-min: 1200px; /* Large screen / wide desktop */
}

html {
  overflow-y: scroll;
}

#root {
  position: absolute;
  min-height: 100%;
  width: 100%;
  min-width: 740px;
  background-color: rgb(245, 245, 245);
}

.root {
  padding: 0 var(--space-8);
}

.container {
  margin: 0 auto;
  min-width: 480px;
  max-width: 1366px; /* Todo Bu kısım daha geniş tasarımları engellemek amacıyla yerleştirilmiştir */
  min-height: calc(100% - 64px);
  width: var(--max-content-width);
  padding-bottom: 128px;
}

.max1024 {
  max-width: 1024px;
  margin: auto;
}

.max380 {
  max-width: 380px;
  margin: auto;
}

.left {
  float: left;
}

.inputStyle {
  margin: 0 var(--space-4);
}

.right {
  float: right;
}

.centerH {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
