.header-root {
  background: white;
  border-bottom: 1px solid lightgray;

  .container {
    margin: 0 auto;
    padding: 4px 0;
    max-width: var(--max-content-width);
    min-width: 640px;
    position: relative;
    min-height: 64px;
  }

  .brand {
    text-decoration: none;
    font-size: 1.75em; /* ~28px */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  .brandTxt {
    margin-left: 10px;
  }

  .banner {
    text-align: center;
  }

  .bannerTitle {
    margin: 0;
    padding: 10px;
    font-weight: normal;
    font-size: 4em;
    line-height: 1em;
  }

  .bannerDesc {
    padding: 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.25em;
    margin: 0;
  }

}
